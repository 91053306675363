import checkboxTemplate from './checkbox.html';

angular.module('org.saga.widget.checkbox', ['org.saga.service']).directive('checkboxWidget', [
   'PropertiesLoader',
   'InstanceExpression',
   'Utils',
   function (PropertiesLoader, InstanceExpression, Utils) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: checkboxTemplate,
         scope: {
            widget: '=',
            required: '=',
            editable: '=',
            instance: '=',
            selected: '=?'
         },
         link: function (scope, element, attrs, form) {
            scope.form = form;
            scope.$watchGroup(['selected.length', 'required'], function (newValues) {
               if (scope.required) {
                  const isValid = newValues[0] > 0;
                  form.$setValidity('requiredCheckbox', isValid);
               } else {
                  form.$setValidity('requiredCheckbox', true);
               }
            });

            scope.$on('$destroy', function () {
               form.$setValidity('requiredCheckbox', true);
            });
         },
         controller: [
            '$scope',
            'DefaultProperties',
            function ($scope, DefaultProperties) {
               PropertiesLoader.load($scope, DefaultProperties.checkbox);
               $scope.autoHideWarnings = !($scope.showValidateWarnings === 'force');

               $scope.touched = false;

               if ($scope.buttonMode === 'false') {
                  $scope.buttonMode = false;
               }

               if (!$scope.result) {
                  $scope.result = 'LIST';
               }

               if (!$scope.selected) {
                  if ($scope.result === 'LIST') {
                     $scope.selected = [];
                  } else {
                     $scope.selected = {};
                     angular.forEach(Utils.getProperties($scope.items), function (i) {
                        $scope.selected[i] = false;
                     });
                  }
               }

               $scope.toggle = function (item, list) {
                  if ($scope.result === 'LIST') {
                     var idx = list.indexOf(item);
                     if (idx > -1) {
                        list.splice(idx, 1);
                     } else {
                        list.push(item);
                     }
                  } else {
                     list[item] = !list[item];
                  }
                  $scope.touched = true;
               };

               $scope.itemDisabled = function (key, item) {
                  var exp = $scope.disabled[key];
                  if (!exp) {
                     return !$scope.editable;
                  }
                  var result = InstanceExpression.eval(exp, $scope.instance, item); // jshint ignore:line
                  return result;
               };

               $scope.exists = function (item, list) {
                  if ($scope.result === 'LIST') {
                     return list && list.indexOf(item) > -1;
                  } else {
                     return list[item];
                  }
               };
            }
         ]
      };
   }
]);
