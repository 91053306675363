import selectTemplate from './select.html';

angular
   .module('org.saga.widget.select', ['org.saga.service'])

   .directive('selectWidget', [
      'PropertiesLoader',
      '$log',
      'EnumerationCache',
      'ListUtils',
      'InstanceExpression',
      '$translate',
      function (
         PropertiesLoader,
         $log,
         EnumerationCache,
         ListUtils,
         InstanceExpression,
         $translate
      ) {
         return {
            restrict: 'E',
            require: '^form',
            controllerAs: 'ctrl',
            template: selectTemplate,
            scope: {
               widget: '=',
               editable: '=',
               instance: '=',
               required: '=',
               selected: '=?',
               values: '=?'
            },
            link: function (scope, element, attrs, form) {
               scope.form = form;
            },
            controller: [
               '$scope',
               'DefaultProperties',
               '_',
               function ($scope, DefaultProperties, _) {
                  PropertiesLoader.load($scope, DefaultProperties.select);
                  $scope.autoHideWarnings = !($scope.showValidateWarnings === 'force');

                  $scope.filterFn = function () {
                     if ($scope.filter) {
                        $scope.items = ListUtils.filter(
                           $scope.items,
                           $scope.filter,
                           function (item) {
                              return InstanceExpression.eval($scope.filter, $scope.instance, item); // jshint ignore:line
                           }
                        );
                     }
                  };

                  function filterSelectedValues (values) {
                     if ($scope.selected && $scope.selected.length > 0 && values) {
                        const filteredArray = _.intersectionWith(
                           values,
                           $scope.selected,
                           _.isEqual
                        );
                        $scope.selected = filteredArray;
                     }
                  }

                  if ($scope.values) {
                     $scope.$watchCollection('values', function (values) {
                        $scope.items = values;
                        $scope.filterFn();
                        filterSelectedValues($scope.items);
                     });
                  } else {
                     if ($scope.enumeration) {
                        EnumerationCache.get($scope.enumeration, function (data) {
                           $scope.items = ListUtils.fromMap(data);
                        });
                     }
                  }

                  $scope.filterFn();

                  filterSelectedValues($scope.items);

                  $scope.getErrorMessage = function (message, value) {
                     return $translate.instant($scope.errors[message], { value: value });
                  };

                  $scope.getLabelExpression = function (item) {
                     const result = InstanceExpression.eval(
                        $scope.labelExpression,
                        $scope.instance,
                        item
                     ); // jshint ignore:line
                     return result;
                  };

                  $scope.$watch('selected', function (value) {
                     if ($scope.required && !value && $scope.form[$scope.widget.name].$touched) {
                        $scope.form[$scope.widget.name].$setValidity('required', false);
                     }
                  });

                  $scope.searchTerm = '';

                  $scope.clearAll = () => {
                     $scope.selected = [];
                  };
                  $scope.selectAll = () => {
                     $scope.selected = $scope.items;
                  };
               }
            ]
         };
      }
   ]);
